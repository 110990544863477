.enter-domain-input-parent {
  width: 70%;
}
.enter-domain-input {
  padding: 10px;
}

.enter-domain-input-button {
  display: flex;
}

.enter-domain-button {
  width: max-content;
  padding: 5px 10px;
  margin-left: 10px;
  font-size: 0.9rem;
  background-color: #000;
}

.enter-domain-publish-button {
  width: max-content;
  padding: 8px 18px;
  font-size: 0.9rem;
  background-color: #000;
}
.enter-domain-skip-button {
  width: max-content;
  padding: 8px 18px;
  font-size: 0.9rem;
  background-color: #000;
  margin-top: 10px;
}

.connected-custom-domain-text {
  font-size: 0.8rem;
  color: var(--text-grey);
  margin-top: 10px;
}

.update-domain-text {
  color: #757575;
  font-size: 15px;
  cursor: pointer;
  margin: 0;
  position: absolute;
  top: 7px;
  right: 7px;
  transition: all ease 0.3s;
}

.update-domain-text:hover {
  color: #000;
}

.signup-link-button {
  padding: 20px;
  width: 50%;
  background-color: #000;
  color: #fff;
  margin-bottom: 40px;
}

.signup-link-success-text {
  text-align: center;
  margin-top: 7px;
  margin-bottom: 20px;
}
