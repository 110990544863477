:root {
  --color-black: #000;
  --white-color: #fff;
  --text-grey: #8798ad;
  --canva-color: #7d2ae8;
  --light-purple: #f1f1ff;
  --chip-color: #2e5bff33;
  --color-primary: #1e45f9;
  --heading-color: "#2E384D";
  --secondary-color: #a1aabd;
  --chip-text-color: #2e5bff;
  --text-input-color: #e0e7ff33;
  --app-background-color: #f4f6fc;
  --text-input-border-color: #e0e7ff;
  --secondary-light-color: #8097b11a;
  --app-drawer-background-color: linear-gradient(
    108.77deg,
    #2645ef -91.14%,
    #8de6ec 245.21%
  );
  --error-color: #db4537;
}

.swal2-html-container {
  white-space: pre-wrap;
}
