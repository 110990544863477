.ads-login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 55vw;
  min-height: 60vh;
  padding: 5% 0;
}

.ads-login-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ads-login-icon {
  width: 4vw;
  background-size: contain;
}

.ads-login-title {
  font-size: 1.3rem;
  margin-bottom: 0;
}

.ads-login-description {
  font-size: 1rem;
  color: var(--text-grey);
}

.ads-login-btn-container {
  width: 51%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1%;
}

.account-Accept-btn {
  width: 25% !important;
  padding: 2%;
  margin: 1%;
}

.account-back-btn {
  width: 25% !important;
  padding: 2%;
  margin: 1%;
  background-color: #f0f0f0;
  color: var(--color-primary);
  font-size: 0.9rem;
}

.ads-login-prop-account-btn-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1%;
}

/* Google disable */
.add-google-acc-id-btn {
  width: 20%;
  padding: 2%;
  margin-top: 10px;
  margin-left: 80%;
}

.google-disable-acc-modal-heading {
  text-align: center;
  color: var(--text-grey);
  font-size: 1.1rem;
}

.google-disable-acc-modal-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
  cursor: pointer;
  padding: 2%;
}

.google-disable-acc-modal-text {
  margin: 0;
  margin-left: 10px;
  font-size: 0.9rem;
}

.load-more-accounts-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.load-more-accounts-btn {
  padding: 0;
  padding: 10px 15px;
  width: max-content;
  margin-top: 20px;
  background-color: #333;
}

.fb-page-text {
  width: max-content;
  color: dodgerblue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 12px;
  margin-top: 20px;
}
