.launch-tab-button-review {
    width: 100%;
    border: none;
    color: white;
    padding: 6%;
    border-radius: 5px;
    background-color: var(--color-primary);
    cursor: pointer;
}

.app-btn-loader-container{
    display: flex;
    align-items: center;
    justify-content: center;
}