.fb-manager-access-main-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #434343;
}

.fb-manager-access-card {
  padding: 20px 25px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.fb-manager-access-main-heading {
  font-size: 22px;
  margin-top: 10px !important;
  font-weight: 600;
}

.fb-manager-access-details-container,
.fb-manager-access-connect-container {
  margin: 40px 0;
}

.fb-manager-access-details-heading,
.fb-manager-access-connect-heading {
  font-weight: 800;
  margin-bottom: 4px !important;
}

.fb-manager-access-details-subheading {
  font-size: 14px;
  margin-bottom: 2px;
}

.fb-manager-access-connect-btn {
  background-color: #316ff6;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  margin: 4px 0;
  opacity: 0.9;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.fb-manager-access-connect-btn:hover {
  opacity: 1;
}

.fb-manager-access-create-asset-link {
  width: max-content;
  color: dodgerblue;
  cursor: pointer;
  text-decoration: underline;
}

.go-back-btn {
  font-size: 14px;
  padding: 10px;
  border-radius: 4px;
  width: max-content;
  cursor: pointer;
  margin-top: 40px;
  transition: all ease 0.3s;
  font-weight: 500;
}
.go-back-btn:hover {
  background-color: #efefef;
}

.selected-business-manager-dropdown {
  margin-top: 0;
  margin-bottom: 40px;
}

.create-business-name-input {
  background-color: transparent !important;
}

.create-business-name-input-child {
  padding: 10px !important;
}

.ads-platform-page-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.fb-manager-access-input-label {
  margin-bottom: 3px;
  font-size: 15px;
  color: gray;
}

/* facebook assets */
.fb-manager-assets-switch-container {
  margin-bottom: 20px;
  display: flex;
}
.fb-manager-assets-switch-subcontainer {
  margin-left: 10px;
}
.fb-manager-assets-switch-subcontainer p {
  margin: 0;
}

.fb-manager-assets-switch-title {
  font-size: 15px;
  margin-top: 3px !important;
  margin-bottom: 3px !important;
  color: gray;
  margin-left: 3px !important;
}

.ads-platform-fb-note-text {
  color: var(--color-primary);
  font-size: 0.8rem;
  cursor: pointer;
  text-align: right;
}

.fb-manager-access-insta-loading-text {
  padding: 8px 12px;
  border: 0.5px solid gray;
  border-radius: 5px;
  color: gray;
}

.fb-manager-access-btn {
  padding: 10px;
  width: 220px;
  border-radius: 4px;
}

.fb-manager-access-video{
  width: 40vw;
  margin-top: 30px;
}

.fb-manager-access-no-ad-acc-error{
  width: 50vw;
  background-color: rgb(226, 197, 197);
  color: rgb(157, 61, 61);
  padding: 10px;
  border-radius: 5px;
}
