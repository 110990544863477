.connected-ad-account-container {
  display: flex;
  margin: 40px 10px;
  justify-content: center;
  flex-direction: column;
}

.connected-ad-account-sub-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.connect-ad-account-text {
  margin-top: 10px;
  margin-bottom: 2px;
  font-size: 15px;
  text-align: center;
}

.connected-ad-account-check-icon {
  font-size: 50px;
  color: rgb(16, 176, 16);
  margin-right: 5px;
}
