.instruction-business-id-copy-div {
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 15px 10px;
  background-color: #efefef;
  justify-content: space-between;
  
}

.instruction-business-id-copy-icon {
  cursor: pointer;
}

.instruction-business-id-copy-div p {
  margin: 0;
}

.instruction-step-div {
  margin-bottom: 30px;
}

.instruction-step-div-img {
  height: 300px;
  object-fit: contain;
  display: block;
  margin-top: 10px;
}
