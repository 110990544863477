.custom-domain-main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #efefef;
}

.custom-domain-content-container {
  background-color: #fff;
  border-radius: 5px;
  margin: 2%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: hidden;
}

.custom-domain-content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
}

.custom-domain-content-header {
  background-color: #000;
  padding: 5px 15px;
}
.custom-domain-content-header p {
  color: #fff;
  font-size: 15px;
}

.check-domain-email-input {
  background-color: transparent;
  margin-bottom: 20px;
}

.check-domain-email-input-child {
  padding: 12px;
  outline: 0;
}

.check-domain-email-button {
  padding: 10px;
  width: 120px;
  background-color: #000;
  position: absolute;
  bottom: 7px;
  right: 7px;
}

.check-domain-email-input-text {
  margin-bottom: 20px;
  width: 70%;
  text-align: center;
}
