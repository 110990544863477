.google-manager-access-main-container {
  max-width: 1200px;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #747474;
  min-height: 100vh;
}

.google-manager-access-card {
  padding: 20px 25px;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.google-manager-access-main-heading {
  font-size: 22px;
  margin-top: 10px !important;
  font-weight: 600;
}

.google-manager-access-connect-container {
  margin-bottom: 10px;
}

.google-manager-access-connect-heading {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px !important;
}

.google-manager-access-account-selection-card {
  /* padding: 2px; */
  overflow: hidden;
  border: 0.5px solid #a5a5a5;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.google-manager-access-connect-btn {
  background-color: #316ff6;
  border: none;
  color: white;
  padding: 8px 16px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  margin: 4px 0;
  opacity: 0.9;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  outline: none;
}

.google-manager-access-connect-btn:hover {
  opacity: 1;
}

.google-manager-access-access-btn {
  background-color: #1d1d1d;
  border: none;
  color: white;
  padding: 12px 16px;
  border-radius: 5px;
  text-align: center;
  font-size: 14px;
  margin: 4px 0;
  opacity: 0.9;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  width: max-content;
}

.google-manager-access-selected-account-card {
  padding: 20px 10px;
  background-color: #fbfbfb;
  border-radius: 5px;
  margin-top: 20px;
}

.google-manager-access-message {
  font-weight: 600;
  color: #287900;
  padding: 8px;
  border-radius: 5px;
  background-color: #e8f5e1;
}
