.connect-social-account-holder .connect-social-account-name {
    text-align: center;
    color: var(--color-black);
}

.social-ads-acc-selection-heading {
    color: var(--secondary-color);
    font-size: 0.8em;
}

.change-btn {
    border: 0;
    width: 100%;
    padding: 2vh;
    cursor: pointer;
    font-weight: 600;
    border-radius: 2px;
    color: var(--color-primary);
    background-color: var(--chip-color);
}

.switch-btn {
    padding: 2vh;
    border-radius: 5px;
    background-color: white;
    color: var(--color-primary);
    border: 1px solid var(--color-primary);
}

.google-create-ad-account {
    color: var(--color-primary);
    font-size: 0.8rem;
    margin-top: 7px;
    cursor: pointer;
}

.social-ads-acc-connect-btn {
    width: 100%;
    margin: 5% 0%;
    padding: 4%;
}

.social-ads-acc-selection-container {
    width: 100% !important;
}

.social-ads-acc-selection-input-container {
    width: 100% !important;
}

.ad-account-selection-container {
    width: 50%;
}

/* Disable google */
.google-disable-note {
    width: 150%;
    font-size: 0.8rem;
    margin-left: -20%;
    margin-top: 20px;
    text-align: center;
    color: var(--text-grey);
}

.google-disable-ad-acc-text-container {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    align-items: center;
}

.google-disable-ad-acc-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
}

.google-disable-ad-acc-text {
    font-size: 0.8rem;
    color: var(--color-primary);
    cursor: pointer;
    white-space: nowrap;
    margin: 0;
}

.google-disable-manager-radio-btn {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 1% 0;
    margin-top: 10px;
}

.google-disable-manager-radio-btn-heading {
    margin: 0;
    font-size: 0.9rem;
    color: var(--text-grey);
    width: 70%;
}

.google-disable-radio-btn-container {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.google-disable-radio-btn {
    width: 15px;
    height: 15px;
    background-size: contain;
}

.google-disable-radio-btn-unchecked {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    border: 2px solid rgb(91, 157, 231)
}

.google-disable-radio-btn-text {
    font-size: 0.9rem;
    margin: 0;
    margin-right: 10px;
}

.google-ad-account-input-label {
    margin-bottom: 5px;
    color: var(--text-grey);
    font-size: 0.8rem;
}