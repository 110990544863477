.tree-view-icon {
    width: 20px;
    height: 20px;
}

.MuiTreeItem-content {
    padding: 1%;
    /* background-color: red; */
    text-align: left;
    border-bottom: 1px solid rgb(221, 221, 221);
}

.MuiTreeItem-label {
    /* border:1px solid red; */
    width: 100%;
    padding: 0.5%;
    font-size: 0.8rem;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent !important;
}

.MuiTreeItem-root.Mui-selected>.MuiTreeItem-content .MuiTreeItem-label:hover, .MuiTreeItem-root.Mui-selected:focus>.MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent !important;
}

.tree-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.tree-label-dynamics {
    justify-content: left;
}

.tree-label>p {
    margin: 0;
}

.tree-label-icon {
    width: 12px;
    height: 12px;
    background-size: contain;
}

.tree-label-icon-dynamic {
    margin-right: 3%;
}

.tree-label-icon-dynamic-rotate{
    margin-right: 3%;
    transform: rotate(270deg);
}

/* Checkbox */

.tree-checkbox-container {
    width:20px;
    height: 20px;
    border: 2px solid grey;

}

/* Hide the browser's default checkbox */
.tree-checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

}

/* Create a custom checkbox */
.tree-checkmark {
    top: 0;
    right: 0 !important;
    height: 20px;
    width: 20px;
}

.tree-checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.tree-checkbox-container input:checked~.tree-checkmark:after {
    display: block;
}

.tree-checkbox-container .tree-checkmark:after {
    right: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid grey;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Checkbox */


