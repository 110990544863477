.app-popover-container{
    display: flex;
    align-items: flex-start;
    padding: 3%;
    width: 500px;
}

.popover-content-container{
    width: 82%;
}

.popover-btn-container{
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
}

.popover-confirm-btn{
    padding: 2% 1%;
    width: 25%;
    margin: 0 5px;
    font-size: 0.9rem;
}

.popover-cancel-btn{
    padding: 2% 1%;
    width: 25%;
    background-color: rgb(230, 230, 230);
    color: var(--color-primary);
    font-size: 0.9rem;

}