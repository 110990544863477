.global-input {
    width: 100%;
    outline: none;
    display: flex;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    background-color: var(--text-input-color);
    border: 1px solid var(--text-input-border-color);
    position: relative;
}

.global-input-child-textarea {
    flex: 1;
    border: 0;
    padding: 3vh;
    resize: none;
    height: auto;
    border-radius: 3px;
    background-color: transparent;
}

.global-input-child {
    font-size: 0.8em;
    flex: 1;
    border: 0;
    padding: 4%;
    /* cursor: pointer; */
    background-color: transparent;
}

.global-input-child::placeholder {
    color: var(--secondary-color);
}

.input-action-text {
    margin: 0;
    cursor: pointer;
    font-size: .8em;
    margin-left: 1vw;
    margin-right: 1vw;
    color: var(--color-primary);
    /* background-color: var(--text-input-color); */
}